import React, { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import cx from 'classnames';
import { Link } from 'gatsby';
import { TFunction, useTranslation } from 'react-i18next';
import { classicRestClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { DataCols } from 'src/components/admin-table/admin-table';
import { TypedFilterObj } from 'src/components/filter-bar/types';
import {
  checklistStatusMap,
  defaultFilterSpacing,
  defaultPageTitleTransKeys,
} from 'src/components/organisations/constants';
import { VIEWING_CONTEXT_MAP, ViewingContext } from 'src/components/organisations/organisation-profile/config';
import { useTenantChecklists, useViewingContext } from 'src/components/organisations/organisation-profile/hooks';
import { ChecklistStatusLabel } from 'src/components/organisations/status-labels';
import { OrgProfileReferrer } from 'src/components/organisations/types';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import Spinner from 'src/components/spinner/spinner';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getRootProviderId } from 'src/config/config';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import { GET_VENUE_CHECKLISTS } from 'src/queries/REST/organisations';
import { ChecklistStatus, VenueChecklist } from 'src/types/classic';
import { GetTenantChecklists, GetVenueChecklists } from 'src/types/classic-queries';
import { SORT_DIRECTION } from 'src/utils/constants/classic';
import { TABLE_IDS } from 'src/utils/constants/tables';
import { buildFiltersConfig, insertRegionFilter } from 'src/utils/helper/filtersConfigUtils';
import { mapToClassicSorts } from 'src/utils/helper/sortOptions';
import ROUTES from 'src/utils/routes';

import * as styles from './checklists.module.less';

interface RegionConfig {
  filter: { insertAtIndex: number; transKeyDefaultOption: string };
  column: { insertAtIndex: number; transKeyTitle: string };
}
type CreateConfigOptions = {
  t: TFunction;
  viewingContext?: ViewingContext;
  regionConfig?: RegionConfig;
  selectedFacilityId: string;
};

type CreateFiltersOptions = CreateConfigOptions & {
  tenantChecklistData?: GetTenantChecklists;
};

type TableItem = VenueChecklist & { id: string };
const useQFT = initQueryFilterTableHook<GetVenueChecklists, TableItem>(GET_VENUE_CHECKLISTS);
const MIN_SEARCH_CHARS = 3;

const makeStatusOptions = (t: TFunction) => {
  const defaultOptions = [{ label: t('any status'), value: '' }];
  const options = Object.entries(checklistStatusMap).map(([key, { transKey }]) => ({
    label: t(transKey),
    value: `${key}`,
  }));

  return defaultOptions.concat(options);
};

const makeChecklistOptions = (data?: GetTenantChecklists) => {
  const tenantChecklists = data?.tenantChecklists ?? [];
  return tenantChecklists.map((tc) => ({ label: tc.Name, value: tc.Tag }));
};

const createFilters = ({
  t,
  regionConfig,
  viewingContext,
  tenantChecklistData,
  selectedFacilityId,
}: CreateFiltersOptions) => {
  const defaultStatus = `${ChecklistStatus.AWAITING_REVIEW}`;
  const statusOptions = makeStatusOptions(t);
  const checklistOptions = makeChecklistOptions(tenantChecklistData);

  const getChecklistFilterPlaceholder = (val: string[]) => {
    if (!val.length) return t('any checklist');
    if (val.length === 1) return checklistOptions.find((opt) => opt.value === val[0])?.label as string;

    return t('placeholderCount', { type: 'checklists', count: val.length });
  };

  const filters: (TypedFilterObj & { initialValue: any })[] = [
    {
      key: 'search',
      type: 'search',
      initialValue: '',
      props: {
        placeholder: t('organisationsModule.filters.searchPlaceholderChecklists'),
        delay: 200,
        minCharsTooltip: { minChars: MIN_SEARCH_CHARS },
        classNames: { wrapper: styles.searchWrapper, input: styles.search },
      },
      spacing: defaultFilterSpacing,
    },
    {
      key: 'checklist',
      type: 'multi-select',
      initialValue: [],
      props: {
        placeholder: getChecklistFilterPlaceholder,
        options: checklistOptions,
        withChevron: true,
        disabled: !checklistOptions.length,
      },
      spacing: defaultFilterSpacing,
    },
    {
      key: 'status',
      type: 'select',
      initialValue: defaultStatus,
      props: {
        options: statusOptions,
        classNames: {
          trigger: styles.filterWidth,
        },
      },
      spacing: defaultFilterSpacing,
    },
  ];

  if (regionConfig) {
    const { insertAtIndex, transKeyDefaultOption } = regionConfig.filter;
    insertRegionFilter(filters, {
      defaultOptionLabel: t(transKeyDefaultOption),
      selectedFacilityId,
      insertAtIndex,
      viewingContext,
      visibility: [VIEWING_CONTEXT_MAP.GLOBAL, VIEWING_CONTEXT_MAP.NATIONAL],
    });
  }

  return buildFiltersConfig(filters);
};

const createColumns = ({ t, regionConfig }: CreateConfigOptions) => {
  const columns: DataCols<TableItem> = [
    {
      key: 'Venue.Name',
      title: t('club name'),
      getValue: (item) => (
        <>
          <Link to={`${ROUTES.MANAGE_ORGANISATIONS}/${item?.Venue?.ID}?referrer=${OrgProfileReferrer.CHECKLISTS}`}>
            {item?.Venue?.Name}
          </Link>
          {item?.Venue?.FriendlyID && <span>{item?.Venue?.FriendlyID}</span>}
        </>
      ),
      widthClassName: cx(styles.tableCell, styles.organisationNameCell),
    },
    {
      key: 'checklist',
      title: t('checklist'),
      getValue: (item) => item?.ChecklistState?.Checklist?.Name,
      sort: false,
      widthClassName: styles.tableCell,
    },
    {
      key: 'status',
      title: t('status'),
      getValue: (item) =>
        typeof item?.ChecklistState.Status === 'number' ? (
          <ChecklistStatusLabel status={item?.ChecklistState?.Status} />
        ) : (
          t('n/a')
        ),
      sort: false,
      widthClassName: styles.tableCell,
    },
  ];

  if (regionConfig) {
    const { transKeyTitle, insertAtIndex } = regionConfig.column;
    const regionColumn = {
      key: 'region',
      title: t(transKeyTitle),
      getValue: (item) => item?.Venue?.County,
      sort: false,
      widthClassName: styles.tableCell,
    };

    columns.splice(insertAtIndex, 0, regionColumn);
  }

  return columns;
};

interface Props {
  transKeyTitle?: string;
  regionConfig?: RegionConfig;
}

const Checklists = ({ regionConfig, transKeyTitle = defaultPageTitleTransKeys.CHECKLISTS }: Props) => {
  const { t } = useTranslation();
  const selectedFacilityId = useOrgId();
  const tenantId = getRootProviderId();
  const { viewingContext } = useViewingContext() ?? {};
  const { data: tenantChecklistData } = useTenantChecklists();

  const filters = useMemo(
    () =>
      createFilters({
        t,
        viewingContext,
        regionConfig,
        tenantChecklistData,
        selectedFacilityId,
      }),
    [t, viewingContext, regionConfig, tenantChecklistData, selectedFacilityId],
  );

  const columns = useMemo(
    () => createColumns({ t, regionConfig, selectedFacilityId }),
    [t, regionConfig, selectedFacilityId],
  );

  const getQueryVarsFromContext = useCallback(
    (ctx) => {
      const { limit, offset } = ctx.paging;
      const { checklist, region, search, status } = ctx.filters;
      const checklistFilter = checklist.length ? checklist : undefined;
      const statusFilter = status ? Number(status) : undefined;
      const defaultSorting = { Property: 'Venue.Name', SortDirection: SORT_DIRECTION.ASCENDING };
      const searchFilter = typeof search === 'string' && search.length >= MIN_SEARCH_CHARS ? search : undefined;

      return {
        input: {
          VenueID: tenantId,
          RegionID: region || undefined,
          Status: statusFilter,
          SearchTerm: searchFilter,
          ChecklistTags: checklistFilter,
          Limit: limit,
          Offset: offset,
          Sort: ctx.sorting ? mapToClassicSorts(ctx.sorting)[0] : defaultSorting,
        },
      };
    },
    [tenantId],
  );

  const {
    components: { FilterBar, AdminTable, AccessoryBar, props },
    query: { error: tableError },
  } = useQFT(
    filters,
    {
      columns,
      tableProps: {
        tableId: TABLE_IDS.CHECKLISTS_LIST,
        rowsPerPageOptions: [25, 50],
      },
    },
    {
      columnSelectorId: TABLE_IDS.CHECKLISTS_LIST,
      mapFiltersToQueryOptions: ({ filters }) => {
        return {
          skip: !viewingContext,
          client: classicRestClient,
          getTotalItems: (data) => data?.venueChecklists?.Total ?? 0,
          transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
            const { limit, offset, sorts } = vars;

            return getQueryVarsFromContext({
              filters,
              paging: { limit, offset },
              sorting: sorts,
            });
          },
          tableId: TABLE_IDS.CHECKLISTS_LIST,
          defaultFetchLimit: 25,
          fetchPolicy: 'no-cache',
        };
      },
      mapDataToTable: (data) => {
        const items = data?.venueChecklists?.Checklists ?? [];
        return items.map((item) => ({
          id: `${item?.Venue.ID}-${item?.ChecklistState?.Checklist?.Tag}`,
          ...item,
        }));
      },
      depsFilterConfig: [filters],
      depsTableConfig: [columns],
    },
  );

  if (!viewingContext) return <Spinner />;

  return (
    <PageMaxWidth>
      <Grid container>
        <PageHeader title={t(transKeyTitle)} />
      </Grid>
      <Panel headerEndContent={<AccessoryBar {...props.accessoryBar} />} extendedPadding>
        <FilterBar {...props.filterBar} defaultValues={{ ...props.filterBar.values, status: '' }} withClearBtn />
        <AdminTable
          {...props.adminTable}
          error={tableError && t('organisationsModule.queryErrors.getVenueChecklists')}
        />
      </Panel>
    </PageMaxWidth>
  );
};

export default Checklists;
