import React, { ReactNode } from 'react';

import { OrganisationModuleFeature } from 'src/components/organisations/types';
import { isOrgModuleFeatureDisabled } from 'src/components/organisations/utils';
import Redirect from 'src/components/util-components/redirect';
import ROUTES from 'src/utils/routes';

interface Props {
  feature: OrganisationModuleFeature;
  children: ReactNode;
}

const OrgModuleAccessControl = ({ children, feature }: Props) => {
  if (isOrgModuleFeatureDisabled(feature)) return <Redirect to={ROUTES.NOT_FOUND} />;

  return <>{children}</>;
};

export default OrgModuleAccessControl;
