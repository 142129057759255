import React from 'react';

import Layout from 'src/components/layout/layout';
import Checklists from 'src/components/organisations/checklists/checklists';
import { getClientProps } from 'src/components/organisations/checklists/client-props';
import OrgModuleAccessControl from 'src/components/organisations/util-components/org-module-access-control';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const ChecklistPage = () => {
  return (
    <Layout>
      <SEO title="Checklists" />
      <PrivateRoute>
        <OrgModuleAccessControl feature="checklists">
          <Checklists {...getClientProps()} />
        </OrgModuleAccessControl>
      </PrivateRoute>
    </Layout>
  );
};

export default ChecklistPage;
